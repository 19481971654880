import React from 'react'
import { BlogLink, HomeContainer } from './styles/Home'
import ParticleBackground from '../../components/particle-background/background';
import Typewriter from 'typewriter-effect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export const Home = () => {
  return (
    <HomeContainer>
      <ParticleBackground></ParticleBackground>
      <Typewriter
        options={{
          strings: ['I am Kanaad Deshpande.', 'I love to write...'],
          autoStart: true,
          loop: true,
        }}
      />
      <BlogLink to="/blogs">
        <FontAwesomeIcon icon={faChevronRight} />
        <FontAwesomeIcon icon={faChevronRight} />
      </BlogLink>
    </HomeContainer>
  )
}
