import React from 'react'
import ParticleBackground from '../../components/particle-background/background'
import Kanaad from "../../Kanaad.jpeg"
import { AboutContainer, ContentContainer, ImageContainer } from '../about/styles/About'

export const About = () => {
  return (
    <AboutContainer>
      <ParticleBackground></ParticleBackground>
      <ImageContainer src={Kanaad} />
      <ContentContainer>
        <div>
        <p>
          Hey there! 👋 I'm Kanaad, the imaginative force steering the ship on this seriously cool website (yes, it's dazzling with stars, and I'm totally aware!). Welcome to my eclectic world. By day, I'm a software engineer at JP Morgan Chase & Co, and at night, I usually sleep (I do not turn into a vampire).
          I'm the kind of person who writes about all sorts of random stuff—think cryptography, cricket, and cards (not the business kind, but the delightful deck-of-playing-cards variety. I'm all about the 3 C's, and yes, I like to sprinkle in a bit of humor here and there). 
          Up until now, these musings have been my little secret, but guess what? I've decided to take the plunge and share them with the world. 
          Why? Well, I recently penned down the book "The Cipher of Limitless Possibilities," with my friends and let's just say it emboldened me. 
          So, here I am, ready to take on the virtual stage. 
          Huge shoutout to everyone who encouraged me to do this—you're awesome!
        </p>
        <br />
        <p>
          Beyond my life in code, did I mention I'm a hobbyist magician? 
          Yep, I'll be conjuring up some magic amidst discussions about my other loves—cricket and my hatred for pineapple on pizza. 
          I love to hate pineapple on pizza.
          Brace yourself for a delightful mishmash of these three passions in my blogs.
        </p>
        <br />
        <p>
          Oh, about my photo? Yeah, I'm no photography expert, and I'm not a fan of posing. I promise I'll swap it out for something less silly when inspiration strikes or when I manage to convince a friend to be my impromptu photographer. 
          Until then, just read the thoughts of Kanaad Deshpande, will you?
        </p>
        </div>
      </ContentContainer>
    </AboutContainer>
  )
}

