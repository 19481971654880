import React from 'react'
import { BlogContainer, TimelineContainer, Card, Info, Title } from './styles/Blog'

export const Blog = () => {
  return (
    <BlogContainer>
      <TimelineContainer>
      <Card isOdd>
        <Info>
          <Title isOdd>Prologue to the blog | December 19, 2023</Title>
          <p>
            Hey there! I thought, let's divide the blog into little chapters. This is by no means a new year resolution. It is just me writing because I love to write. Click on me, and start reading, why don't ya?
          </p>
        </Info>
      </Card>
      <Card isEven>
        <Info>
          <Title isEven>Chapter 1 | Oh Cipher My Cipher | December 23, 2023</Title>
          <p>
            Exactly a month after the publication of my book, I write this blog to explain my book and its motivation. You might want to read this.
          </p>
        </Info>
      </Card>
      <Card isOdd>
        <Info>
          <Title isOdd>Chapter 2 | The Trek | December 31, 2023</Title>
          <p>
            Yes, I sit at home, and write on New Year's eve, because everywhere everything is too crowded. I hate crowds. So here's the story of the Trek that became a wonderful memory.
          </p>
        </Info>
      </Card>
      <Card isEven>
        <Info>
          <Title isEven>Chapter 3 | Magic? | january 11, 2024</Title>
          <p>
            Yes, I do not write my blogs daily. Nor is there a specific frequency. But here are my thoughts about magic.
          </p>
        </Info>
      </Card>
      {/* Add more Card components as needed */}
    </TimelineContainer>
    </BlogContainer>
  )
}

