import React from "react";
import Navbar from "./components/navbar/navbar";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/home";
import {Blog} from "./pages/blogs/blog";
import {About} from "./pages/about/about";
import { Container } from "./styles/App";
import { Error } from "./pages/error/Error"

function App() {
  return (
    <Container>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="*" element={<Error />} />
    </Routes>
    </Container>
  );
}

export default App;
