import React, { useState, useEffect } from 'react'
import { NavContainer, NavLink } from './styles/Navbar'

const Navbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleNavLinkClick = (to) => {
    setActiveLink(to);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <NavContainer isScrolled={isScrolled}>
      <NavLink to="/" isActive={activeLink === '/'} onClick={() => handleNavLinkClick('/')}>Home</NavLink>
      <NavLink to="/about" isActive={activeLink === '/about'} onClick={() => handleNavLinkClick('/about')}>About</NavLink>
      <NavLink to="/blogs" isActive={activeLink === '/blogs'} onClick={() => handleNavLinkClick('/blogs')}>My Journey</NavLink>
    </NavContainer>
  )
}

export default Navbar